import React from 'react'
import '../styles/productDetails.css'
import Helmet from 'react-helmet'

import img2 from '../images/Rect8.png'
import logo from '../images/headerLogo.png'
import Layout from '../components/layout'
import square from '../images/square.png'

import rectItems1 from '../images/kitchen/Rectangle 34.png'
import rectItems2 from '../images/kitchen/Rectangle 31.png'

import product1 from '../images/kitchen/image 24.png'
import product2 from '../images/kitchen/image 25.png'
import product3 from '../images/kitchen/image 26.png'
import product4 from '../images/kitchen/image 27.png'
import product5 from '../images/kitchen/image 28.png'
import product6 from '../images/kitchen/image 30.png'
import product7 from '../images/kitchen/image 31.png'
import product8 from '../images/kitchen/image 32.png'
import Contact from '../components/contact'




const Product3 = () => {

  return (

    
    <div className=' font-hind'>
      <Layout>
      <Helmet title="Kitchen Refrigeration Systems | Better Freezing Solutions"
          meta={[
                    {
                        name:'charSet',
                        content:'utf-8',
                    },
                    {
                        name:'description',
                        content:'Complete solution for refrigeration systems.We provide supermarket refrigeration,cold storage refrigeration,kitchen refrigeration medical refrigeration,freezers,coolers.',
                    }
                ]}
        />


        <div className='productDetailSection'>
          
          <div className='flex  relative'>
            <img className='img2 absolute right-0' src={img2} alt='' />

            <div className='PDItemsSection absolute right-0 z-30 flex sm:justify-end justify-center'>
              <div className='flex items-end'>
                <img className='PDImage1 absolute z-10' src={rectItems1} alt='item' />
                <img className='PDImage2' src={rectItems2} alt='item' />
              </div>
            </div>
          </div>

          
          
          <div className='productTitleSection flex justify-center text-white'>
            <div className='z-30'>
              
              <div className='flex justify-center items-center  sm:items-end sm:justify-start px-6'>
                <img className='logo ' src={logo} alt='' />
                <h1 className='productTitle hidden sm:flex text-white'>Kitchen Refrigeration</h1>
                <h1 className='productTitle block sm:hidden text-white'>Kitchen<br/>Refrigeration</h1>
              </div>
              
              <div className=''>
                <div className='PDListGrid grid lg:grid-cols-2  sm:grid-cols-2 grid-cols-1 xl:gap-8 xl:gap-y-2 lg:gap-5 lg:gap-y-2  md:gap-0 md:gap-y-1'>
                  <div className='flex items-center justify-start'> <img className='mr-2' src={square} alt='image1' /><h1 className='PDListText'>Reach-in Chillers and Freezers</h1> </div>
                  <div className='flex items-center justify-start'> <img className='mr-2' src={square} alt='image2' /><h1 className='PDListText'>Blast Freezers</h1> </div>
                  <div className='flex items-center justify-start'> <img className='mr-2' src={square} alt='image3' /><h1 className='PDListText'>Undercounter Chillers and Stations</h1> </div>
                  <div className='flex items-center justify-start'> <img className='mr-2' src={square} alt='image4' /><h1 className='PDListText'>Back Bar Chiller</h1> </div>
                  <div className='flex items-center justify-start'> <img className='mr-2' src={square} alt='image5' /><h1 className='PDListText'>Saladettes and Sandwich Stations</h1> </div>
                  <div className='flex items-center justify-start'> <img className='mr-2' src={square} alt='image6' /><h1 className='PDListText'>Ice Machines</h1> </div>
                </div>
              </div>

              
            </div>
          </div>

        </div>

        <div className='flex justify-center'>
          <h1 className='PDText'>
          Our products are designed and tested to deliver optimum perfomance in harsh conditions like high ambient 
          temperature, rough usage, transportation and humidity
            <br/>
            Designed by Italian experts and manufactured to strict quality and production standards these products 
            have tested at internationally accredited labs. 
              
          </h1>
        </div>

        <div className='flex justify-center'>
          <div className='PDProductGrid grid lg:grid-cols-4 md:grid-cols-3 sm:grid-cols-2 grid-cols-1 2xl:gap-10 gap-3 gap-y-5 pb-4'>
            <div className='PdProductItems flex justify-center items-center'><img className='p-6' src={product1} alt='Give suitable images names for corresponding images. For, different refrigeration systems also.' /></div>
            <div className='PdProductItems flex justify-center items-center'><img className='p-6' src={product2} alt='Give suitable images names for corresponding images. For, different refrigeration systems also.' /></div>
            <div className='PdProductItems flex justify-center items-center'><img className='p-6' src={product3} alt='Give suitable images names for corresponding images. For, different refrigeration systems also.' /></div>
            <div className='PdProductItems flex justify-center items-center'><img className='p-6' src={product4} alt='Give suitable images names for corresponding images. For, different refrigeration systems also.' /></div>
            <div className='PdProductItems flex justify-center items-center'><img className='p-6' src={product5} alt='Give suitable images names for corresponding images. For, different refrigeration systems also.' /></div>
            <div className='PdProductItems flex justify-center items-center'><img className='p-6' src={product6} alt='Give suitable images names for corresponding images. For, different refrigeration systems also.' /></div>
            <div className='PdProductItems flex justify-center items-center'><img className='p-6' src={product7} alt='Give suitable images names for corresponding images. For, different refrigeration systems also.' /></div>
            <div className='PdProductItems flex justify-center items-center'><img className='p-6' src={product8} alt='Give suitable images names for corresponding images. For, different refrigeration systems also.' /></div>
          </div>
        </div>
        <Contact/>
      </Layout>
    </div>
  )
}

export default Product3
